<template>
    <section>
        <section class="legal_advice">
        <div class="legal_advice_container">
            <h2 class="title">Asesoramiento Legal Frente a Reclamos</h2>
            <div class="legal_advice_content paragraph">
                <p>Teniendo en cuenta que el criador siempre cuenta con la mejor intención frente a un reclamo, y llegando generalmente a una solución pacífica pero sabiendo que en algunos casos hemos detectado abuso por parte de compradores mal intencionados, acercamos un <b>asesoramiento legal frente a reclamos</b>. Desde este Registro Oficial Canino abogamos siempre por una solución que contemple el Bienestar Animal por delante de cualquier opción.
                    <br><br>
                Considerando que frecuentemente se producen reclamos por parte de los compradores, muchos de los cuales se derivan de la mala interpretación del “contrato” entre proveedor (criador) y el consumidor (comprador), acercamos información sobre las disposiciones que contempla Defensa al Consumidor para la compra de animales de compañía o “mascotas” en general.
                    <br><br>
                La primera consideración a tener en cuenta es que, aunque muchas personas lo consideramos “emocionalmente incorrecto”, los animales son para la ley “cosas muebles no consumibles”. La ley indica, entonces, que “El proveedor deberá respetar la oferta anunciada y convenida por la compra de la mascota”. Tanto en su edad, sexo, raza, color, y demás atribuciones ofrecidas.
                    <br><br>
                <b>Articulo 8° LDC</b>. “Las precisiones formuladas en la publicidad o en anuncios prospectos, circulares u otros medios de difusión obligan al oferente y se tienen por incluidas en el contrato con el consumidor”.
                    <br><br>
                Es muy importante entonces que al OFERTAR en los canales de venta seamos MUY PRECISOS en la oferta, así como también entreguemos bajo firma por escrito la mayor cantidad de especificaciones. A saber: “Advertencias e Instrucciones: <b>El proveedor deberá especificar y dar instrucciones sobre cuidados específicos de la raza, régimen alimentario, régimen sanitario y advertir en forma destacada cualquier posibilidad de daño que la mascota pueda causar a usted, a su familia o a extraños, ya sea por su naturaleza o por trasmisión de enfermedades</b>”.
                    <br><br>
                <b>ARTICULO 5º</b> - Protección al Consumidor: Las cosas y servicios deben ser suministrados o prestados en forma tal que, utilizados en condiciones previsibles o normales de uso, no presenten peligro alguno para la salud o integridad física de los consumidores o usuarios”. <br>
                    <br>
                Un problema que se presenta a menudo son reclamos por enfermedades o problemas físicos. Al respecto, es muy importante comprender que no hay reclamos posible sin la existencia de un informe veterinario COMPLETO Y FIRMADO por el profesional, el mismo debe ser verificable y que sea el resultante de haber aplicado los análisis o estudios  necesarios que avalen el diagnóstico. 
                <br><br>
                En caso de <b>muerte</b> deberá mediar una autopsia donde el profesional haga constar la causal PROBADA de muerte, en ambos caso el profesional actuante debe ser el que el criador disponga o acuerden entre ambas partes.
                    <br><br>
                Lamentablemente, hemos sabido de casos de reclamos de compradores que bajo la amenaza del ESCRACHE público, amedrentan al criador y exigen los costos del tratamiento y/o un nuevo ejemplar sin entregar, estudios clínicos, o lo que es peor negándose a restituir el cachorro. Algunos criadores han cedido de buena fe, comprobándose a posteriori que el primer ejemplar estaba vivo e incluso esos ejemplares se intentaron utilizar para la cría. <br>

                <span class="span1">Es muy importante que se EXIJA, si así lo considere, la entrega del primer ejemplar.<br> Si deciden no hacerlo, soliciten sin condición la constancia de castración del mismo, la que deberá ponerse a disposición <br>del Kennel Club Digital, junto con una nota del criador, para que podamos tomar cuenta de ello y darlos de baja como reproductores.</span>
                

                <h3>RECLAMOS POR DEFICIENCIA</h3>
                <b>Deficiencias no advertidas:</b> <i>El vendedor deberá responder cuando no advirtió la existencia de deficiencias en el animal vendido, como, displasia,  taras oculares,  u otras. En su caso deberá acompañar un certificado de un veterinario, como se mencionó anteriormente</i>.
                    <br><br>
                ARTICULO 9º - Cosas Deficientes Usadas o Reconstituidas. Cuando se ofrezcan en forma pública a consumidores potenciales indeterminados cosas que presenten alguna deficiencia, que sean usadas o reconstituidas debe indicarse la circunstancia en forma precisa y notoria.
                    <br><br>
                En nuestro caso, esto se traduce en <b>no acceder a ningún reclamo que no se avale con los estudios y certificados del profesional</b>, y que en caso que vendamos un ejemplar con un «defecto» conocido, a los fines de que solo cumpla la función de compañía, esto quede especificado por escrito en el momento de la entrega.

                <div class="garantia">
                    <div class="columna1">
                        <h3>GARANTÍA LEGAL</h3>
                        El vendedor deberá responder por las deficiencias que presenta la mascota,  (deficiencias de origen y no de mal cuidado o falta de cuidados por parte del comprador), por el plazo de SEIS (6) MESES o mayor si se ha convenido.
                        <br><br>
                        ARTICULO 11. - Garantías. Cuando se comercialicen cosas muebles no consumibles conforme lo establece el artículo 2325 del Código Civil, el consumidor y los sucesivos adquirentes gozarán de garantía legal por los defectos o vicios de cualquier índole, aunque hayan sido ostensibles o manifiestos al tiempo del contrato, cuando afecten la identidad entre lo ofrecido y lo entregado, o su correcto funcionamiento. <br> 
                        La garantía legal tendrá vigencia por TRES (3) meses cuando se trate de bienes muebles usados y por SEIS (6) meses en los demás casos a partir de la entrega, pudiendo las partes convenir un plazo mayor. En caso de que la cosa deba trasladarse a fábrica o taller habilitado el transporte será realizado por el responsable de la garantía, y serán a su cargo los gastos de flete y seguros y cualquier otro que deba realizarse para la ejecución del mismo.
                    </div>
                </div>

                De esto último se desprende que es nuevamente muy importante que se especifique tanto en las publicidades, como en el momento de la entrega todas las características visibles. Claramente, cualquier criador responsable deberá responder a cualquier eventualidad no advertida, pero para ello es necesario que el comprador ponga a disposición el ejemplar, y podrá optar por hacerse cargo de su tratamiento en el establecimiento que el criador decida hasta su completa recuperación o el reemplazo del mismo contra entrega del primer ejemplar.
                <br><br>
                Es la ley la que no otorga ningún estatus diferente a una mascota, aunque los criadores sí lo hacemos de buena fe, por lo tanto a la ley debemos ajustarnos. Recomendamos siempre que las partes traten de llegar a acuerdos pacíficos por el bien de los animales, pero lamentamos advertir que recientemente el “chantaje emocional”, sumado al daño producido públicamente en redes sociales, ha llevado al abuso. Creemos que no debemos aceptar el asedio ni la amenaza, anteponiendo la vía civil si ello ocurre. En un contrato de partes, ambos tienen derechos y obligaciones, y deben cumplirlas conforme a la ley.
                <br><br>
                <hr>
                <span class="span2" style="font-size: 1.3rem;"><b>Ponemos a disposición al letrado de la Institución. En caso de ser necesario asesoramiento legal no duden en pedirnos su contacto, dados los conocimientos específicos con los que cuenta en la materia.
                    <br>
                Recuerda que usar la <a href="/denuncia-de-venta-de-canino">Denuncia de Venta</a> y enviar una copia al Kennel Club Digital es una buena práctica.
                    <br><br></b>Finalmente, sé solidario y ético con tus colegas criadores, no te sumes al agravio sin conocer a fondo el contexto, podrías estar alguna vez en la misma situación.</span>
                <br><br>
                <span class="span3" style="font-size: 1rem; float: right;">Fuente: Protectora.org.ar</span>
                </p>
            </div>
        </div>
    </section>
    </section>
</template>


<script setup>
import { onMounted, onUnmounted } from 'vue';
import { canonicalService } from '../canonicalService';
import { titleChange } from '../titleChange';

onMounted(() => {
  canonicalService.addLink('https://kennelclubargentino.org.ar/asesoramiento-frente-a-reclamos/');
});
onUnmounted(() => {
  canonicalService.removeLink('https://kennelclubargentino.org.ar/asesoramiento-frente-a-reclamos/');
});

titleChange.setTitle('/asesoramiento-frente-a-reclamos', 'Asesoramiento Legal Frente a Reclamos')

</script>


<style lang="scss" scoped>
a{
    text-decoration: none;
}

.legal_advice{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
}
.legal_advice_container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
}

.title{
    text-align: center;
    color: #243757;
    font-size: 45px;
    padding-bottom: 0;
}
.paragraph{
    text-align: justify;
    color: grey;
    font-size: 22px;
    text-align: left;
    line-height: 1.5;
    word-spacing: 7px;
}

.span1{
    width: 70%;
    color: rgb(36, 55, 87,0.8) ;
    margin: 40px auto;
    border: 2px solid rgb(0, 0, 0, 0.3);
    padding: 15px;
    display: grid;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.garantia{
    display: flex;
}

.columna1{
    flex: 1;
}

.img_garantia{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img_garantia img{
    width: 700px; 
    height: 600px; 
    border-radius: 10px;
    margin: 90px 0 80px 80px;
}
@media (max-width: 1400px){
    .legal_advice{
        padding-top: 160px; 
    }
}

@media (max-width: 1280px){
    .legal_advice{
        padding-top: 90px; 
    }

    .legal_advice_container{
        padding: 10px 100px 30px 100px;
    }

    .title{
        font-size: 35px;
    }
    .paragraph{
        font-size: 20px;
        word-spacing: 5px;
        text-align: justify;
    }
    
    .garantia{
        grid-template-columns: repeat(2, minmax(0, 1fr)); 
        // gap: 20px;
    }
    .columna1{
        grid-column: 1;
    }
}

@media (max-width: 800px){
    .legal_advice{
        padding: 10px;
        padding-bottom: 20px;
    }

    .legal_advice_container{
        padding: 10px 35px;
    }

    .title{
        font-size: 25px;
    }

    .paragraph{
        font-size: 14px;
    }

    .span1{
        width: unset;
    }

    .span2{
        font-size: 14px !important;
    }

    .span3{
        font-size: 11px !important;
    }

}
</style>