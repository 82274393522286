<template>
  <section class="dog_pattern_container">
    <div class="container">
      <h2 class="dog_pattern_content title">Patrón de los Mantos de los Perros </h2>
      <h3 class="dog_pattern_content title2">(Dibujo de los Mantos) </h3>
      
      <p class="dog_pattern_content subtitle"> Las imágenes son ilustrativas. <br> Puede que tu perro tenga ese patrón a pesar  de <br>no estar ilustrada la raza en una foto.</p> 
      <Pattern/>
    </div>
  </section>
</template>
  
  
<script setup>
  import Pattern from '@/components/Pattern.vue';
  import { onMounted, onUnmounted } from 'vue';
  import { canonicalService } from '../canonicalService';
  import { titleChange } from '../titleChange';

  onMounted(() => {
    canonicalService.addLink('https://kennelclubargentino.org.ar/patrones-en-los-perros-dibujo/');
  });
  onUnmounted(() => {
    canonicalService.removeLink('https://kennelclubargentino.org.ar/patrones-en-los-perros-dibujo/');
  });
  
  
  titleChange.setTitle('/patron-de-los-mantos-de-los-perros', 'Patrón de los Mantos de los Perros (Dibujo de los Mantos)');

</script>
  
  
<style lang="scss" scoped>
  .dog_pattern_container{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
  }
  
  .container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
  }
  
  .title{
    text-align: center;
      color: #243757;
      font-size: 45px;
      padding-bottom: 0;
      margin-bottom: 15px;
  }

  .title2{
    text-align: center;
    color: #243757;
    font-size: 2rem;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 40px;
  }


  .subtitle{
    text-align: center;
    color: grey;
    font-size: 22px;
    margin-bottom: 60px;
  }

  .subt{
    text-align: center;
    font-size: 1.4rem;
    margin: 40px;
  }

  @media (max-width: 1280px){
    .dog_pattern_container{
      padding-top: 90px; 
    }
    .container{
      padding: 10px 100px 30px 100px;
    }
    .title{
      font-size: 35px;
    }
    .subtitle{
      font-size: 20px;
      margin-bottom: 40px;
    }
  }

  @media (max-width: 800px){
    .dog_pattern_container{
      padding: 10px;
      padding-bottom: 20px;
    }

    .container{
      padding: 10px 30px;
    }

    .title{
        font-size: 25px;
        text-wrap: balance;
    }

    .title2{
      font-size: 20px;
      text-wrap: balance;
      margin-bottom: 25px;
    }

    .subtitle{
        font-size: 18px;
        text-wrap: balance;
        margin-bottom: 0;
    }
  }
</style>