<template>
    <section class="canine_groups_container">
      <div class="container">
        <h2 class="canine_groups_content title">Grupos y Razas Caninas</h2>
        
        <p class="canine_groups_content subtitle"> Los perros se juzgan en 7 grupos según la función para la cual fue creada la raza. <br> Algunas razas han cambiado su función y han sido reclasificadas.</p> 
        <Races/>
      </div>
    </section>
  </template>
  
  
  <script setup>
  import Races from '@/components/Races.vue';
  import { onMounted, onUnmounted } from 'vue';
  import { canonicalService } from '../canonicalService';
  import { titleChange } from '../titleChange';

  onMounted(() => {
    canonicalService.addLink('https://kennelclubargentino.org.ar/grupos-caninos/');
  });
  onUnmounted(() => {
    canonicalService.removeLink('https://kennelclubargentino.org.ar/grupos-caninos/');
  });
  
  titleChange.setTitle('/grupos-caninos', 'Grupos y Razas Caninas');
  
  </script>
  
  
  <style lang="scss" scoped>
  .canine_groups_container{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
  }
  
  .container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
  }
  
  .title{
      text-align: center;
      color: #243757;
      font-size: 45px;
      padding-bottom: 0;
      margin-bottom: 15px;
    }
  
    .subtitle{
      text-align: center;
      color: grey;
      font-size: 22px;
      margin-bottom: 60px;
    }

    .subt{
      text-align: center;
      font-size: 1.4rem;
      margin: 40px;
    }

  @media (max-width: 1280px){
    .canine_groups_container{
      padding-top: 90px; 
    }
    .container{
      padding: 10px 100px 30px 100px;
    }
    .title{
      font-size: 35px;
    }
    .subtitle{
      font-size: 20px;
      margin-bottom: 40px;
    }
  }
  @media (max-width: 800px){
    .title{
        font-size: 2rem;
    }

    .canine_groups_container{
      padding: 0;
      padding-top: 50px;
    }
    .container{
      width: 85%;
      
    }
  }
  @media (max-width: 800px){
    .canine_groups_container{
      padding: 10px;
      padding-bottom: 20px;
    }

    .container{
      padding: 10px 30px;
    }

    .title{
        font-size: 25px;
        text-wrap: balance;
    }

    .subtitle{
        font-size: 18px;
        text-wrap: balance;
        margin-bottom: 0;
    }
  }
  </style>