<template>
    <section class="forms">
        <div class="forms_container">
            <h2 class="forms_content title">Formularios para Descargar y Completar </h2>
            <div class="div-flex">
                <div class="forms_div">
                    <ul class="pdf_list paragraph">
                        <li class="pdf_item"><a href="calendario-de-gestacion.pdf" download>Calendario de Gestación en Perros</a></li>
                        <li class="pdf_item"><a href="conformidad-de-servicio.pdf" download>Conformidad de Servicio / Monta</a></li>
                        <li class="pdf_item"><a href="control-de-displasia.pdf" download>Placa de Displasia</a></li>
                        <li class="pdf_item"><a href="convenio-de-cesion.pdf" download>Convenio de Cesión de Cachorro</a></li>
                    </ul>
                </div>
                <div class="forms_img"> <img src="../assets/forms.png"></div>
            </div>
            
        </div>
    </section>
</template>


<script setup>
import { titleChange } from '../titleChange';

titleChange.setTitle('/formularios-pdf', 'Formularios para Descargar y Completar ');
</script>


<style lang="scss" scoped>
.forms{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
}
.forms_container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 0;
}
.div-flex{
    display: flex;
}

.forms_div{
    padding-top: 40px;
    padding-bottom: 100px;
}

.forms_img{
  position: relative;
  display: flex;
  align-items:end;
  justify-content: end;
  animation: fade-in 1.5s ease-in-out forwards;
  width: 60%;
}

.forms_img img{
    width: 50%;
    animation: fade-in 1.5s ease-in-out forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.title{
    text-align: center;
    color: #243757;
    font-size: 45px;
    padding-bottom: 0;
}
.paragraph{
    list-style: circle;
    text-align: justify;
    color: grey;
    font-size: 25px;
    text-align: left;
    line-height: 1.5;
    word-spacing: 7px;
}
.pdf_list{
    list-style: circle;
    width: 100%;
}

.pdf_item{
    margin-top: 40px;
    opacity: 0; 
    animation: list-in 0.5s forwards;
}
.pdf_list li:nth-child(1) {
    animation-delay: 0s;
}

.pdf_list li:nth-child(2) {
    animation-delay: 0.5s; 
}

.pdf_list li:nth-child(3) {
    animation-delay: 1s;
}
.pdf_list li:nth-child(4) {
    animation-delay: 1.5s;
}
.pdf_list li:nth-child(5) {
    animation-delay: 2s;
}
.pdf_list li:nth-child(6) {
    animation-delay: 2.5s;
}

@keyframes list-in {
    to {
        opacity: 1;
    }
}

.pdf_item a{
    color: rgb(68, 67, 67);
    text-decoration: none;
}

.pdf_item a:visited{
    color: #243757;
}

@media (max-width: 1366px){
    .forms_img img{
        width: 70%;
    }

    .forms{
        padding-top: 160px; 
    }
}

@media (max-width: 1280px){
    .forms{
        padding-top: 90px; 
    }
    .forms_container{
        padding: 10px 100px 0 100px;
    }
    .title{
        font-size: 35px;
    }
    
    .paragraph{
        font-size: 20px;
        word-spacing: 5px;
        text-align: justify;
        
        list-style: circle;
    }

    .forms_div{
        padding-top: 20px;
        padding-bottom: 60px;
    }

    .forms_img img{
        width: 70%;
    }

    .pdf_item{
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .pdf_list{
        margin: 0;
    }
}

@media (max-width: 800px){
    .forms{
        padding: 10px;
        padding-bottom: 20px; 
    }

    .forms_container{
        padding: 10px 35px;
        padding-bottom: 0;
    }

    .title{
        font-size: 25px;
    }

    .paragraph{
        font-size: 15px;
        text-align: left;
    }

    ul{
        padding-left: 10px;
    }

    .div-flex{
        display: unset;
    }

    .forms_div{
        padding-top: 10px;
        padding-bottom: 0;
    }

    .forms_img{
        display: block;
        width: 80%;
        margin: 0 auto;
        height: 227.317px;
    }

    .forms_img img{
        width: 100%;
        height: 227.317px;
    }
}
</style>